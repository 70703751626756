<template>
    <v-container id="task-tables" fluid tag="section">
        <base-material-card
        icon="mdi-clipboard-text"
        :title="$t('task.title')"
        class="px-5 py-3"
        >
            <dialog-message
                :dialog_message="dialog_message"
                :message="message"
            ></dialog-message>
            <v-dialog
                v-model="dialog_form"
                max-width="500px"
                @click:outside="close"
            >
                <v-card>
                    <v-card-text>
                        <v-container class="pa-0">
                            <v-row dense>
                                <v-col cols='12'>
                                    <v-text-field
                                        v-model="item.key"
                                        :label="$t('system_variable.key')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols='12'>
                                    <v-text-field
                                        v-model="item.value"
                                        :label="$t('system_variable.value')"
                                        :hint="$t('system_variable.hint')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols='12'>
                                    <v-text-field
                                        v-model="item.description"
                                        :label="$t('system_variable.description')"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                            {{ $t("common.cancel") }}
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="confirm">
                            {{ $t("common.save") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-col class="text-right">
                <v-btn color="primary" dark class="mb-2" @click="createVariable">
                <v-icon left>mdi-pencil-plus</v-icon>
                    {{ $t("system_variable.createVariable") }}
                </v-btn>
            </v-col>
            <v-data-table
                :headers="headers"
                :items="items"
                :loading="loading"
                :options.sync="options"
                :server-items-length="totalItems"
                :footer-props="footerProps"
                multi-sort
            >
                <template v-slot:item.update_time="{ item }">
                    {{ TransDate(item.update_time) }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-flex>
                        <v-btn
                            color="primary"
                            class="mx-2"
                            small
                            @click="updateValue(item)"
                        >
                            {{ $t("system_variable.updateValue") }}
                        </v-btn>
                    </v-flex>
                </template>
                <template v-slot:top="{ pagination, options, updateOptions }">
                    <v-data-footer
                        dense
                        :show-first-last-page="footerProps.showFirstLastPage"
                        :show-current-page="footerProps.showCurrentPage"
                        :first-icon="footerProps.firstIcon"
                        :last-icon="footerProps.lastIcon"
                        :prev-icon="footerProps.prevIcon"
                        :next-icon="footerProps.nextIcon"
                        :pagination="pagination"
                        :options="options"
                        @update:options="updateOptions"
                        :itemsPerPageOptions="footerProps.itemsPerPageOptions"
                    />
                </template>
                <template slot="no-data">
                <div>
                    {{ $t('common.noData') }}
                </div>
                </template>
            </v-data-table>
        </base-material-card>
    </v-container>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { LBType, LBTypeItems, TaskStatus, TaskStatusItems } from '@/definition'

export default {
    data: (vm) => ({
        dialog_form: false,
        dialog_type: 0,
        loading: false,
        items: [],
        options: {},
        totalItems: 0,
        footerProps: {
            showFirstLastPage: true,
            showCurrentPage: true,
            firstIcon: "mdi-chevron-double-left",
            lastIcon: "mdi-chevron-double-right",
            prevIcon: "mdi-chevron-left",
            nextIcon: "mdi-chevron-right",
            itemsPerPageOptions: [5, 10, 20],
        },
        dialog_message: false,
        message: {
            title: "",
            text: ""
        },
        headers: [
            {
                sortable: false,
                text: vm.$i18n.t("system_variable.key"),
                value: "key",
            },
            {
                sortable: false,
                text: vm.$i18n.t("system_variable.value"),
                value: "value",
            },
            {
                sortable: false,
                text: vm.$i18n.t("system_variable.description"),
                value: "description",
            },
            {
                sortable: false,
                text: vm.$i18n.t("system_variable.update_time"),
                value: "update_time",
            },
            {
                sortable: false,
                text: '',
                value: "actions",
            },
        ],
        currentId: null,
        item: {
            key: '',
            value: '',
            description: '',
        },
    }),
    computed: {
        ...mapState(["token"]),
    },
    methods: {
        ...mapMutations(["setTitle"]),
        close() {
            this.dialog_form = false;
            this.loading = false;
        },
        getApi() {
            this.loading = true;
            const currentObj = this;
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;
            const url =
                process.env.VUE_APP_SERVER_URL +
                "/backend/system_variable/";
            let config = {
                headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + this.token,
                },
                params: {
                    page: page,
                    page_size: itemsPerPage,
                },
            };

            this.axios
                .get(url, config)
                .then(function (response) {
                currentObj.loading = false;
                currentObj.setResult(response.data);
                })
                .catch(function (error) {
                console.log(error);
                console.log(error.response);
                currentObj.loading = false;
                currentObj.message.title = currentObj.$t("get-api-fail");
                currentObj.message.text = JSON.stringify(error.response.data);
                currentObj.dialog_message = !currentObj.dialog_message;
                });
        },
        setResult(data) {
            this.totalItems = data.count;
            this.items = data.results;
        },
        createVariable(){
            this.dialog_type = 0
            this.dialog_form = true;
            this.item = {
                key: '',
                value: '',
                description: '',
            }
        },
        updateValue(target) {
            this.dialog_type = 1
            this.currentId = target.id
            this.item.key = target.key
            this.item.value = target.value
            this.item.description = target.description
            this.dialog_form = true;
        },
        confirm() {
            if (this.dialog_type===0){
                this.save()
            } else {
                this.updateItem()
            }
        },
        save() {
            this.loading = true;
            const currentObj = this;
            const url = process.env.VUE_APP_SERVER_URL + "/backend/system_variable/";
            let config = {
                headers: {
                "Content-Type": "application/json",
                Authorization: "token " + this.token
                }
            };
            let data = {
                key: this.item.key,
                value: this.item.value,
                description: this.item.description,
            };
            this.axios
                .post(url, data, config)
                .then(function(response) {
                currentObj.loading = false;
                alert("送出成功");
                currentObj.getApi();
                currentObj.close();
                })
                .catch(function(error) {
                console.log(error);
                console.log(error.response);
                currentObj.loading = false;
                currentObj.message.title = currentObj.$t("save-fail");
                currentObj.message.text = JSON.stringify(error.response.data);
                currentObj.dialog_message = !currentObj.dialog_message;
                });
        },
        updateItem() {
            this.loading = true;
            const currentObj = this;
            // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
            const url =
                process.env.VUE_APP_SERVER_URL + "/backend/system_variable/" + this.currentId + "/";
            let config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "token " + this.token
                }
            };
            let data = {
                key: this.item.key,
                value: this.item.value,
                description: this.item.description,
            };
            this.axios
                .patch(url, data, config)
                .then(function(response) {
                    currentObj.loading = false;
                    alert("送出成功");
                    currentObj.getApi();
                    currentObj.close();
                })
                .catch(function(error) {
                    console.log(error);
                    console.log(error.response);
                    currentObj.loading = false;
                    currentObj.message.title = currentObj.$t("update-fail");
                    currentObj.message.text = JSON.stringify(error.response.data);
                    currentObj.dialog_message = !currentObj.dialog_message;
                });
        }
    },
    watch: {
        options: {
            handler() {
                this.getApi();
            },
        },
    },
    mounted() {
        this.setTitle(this.$i18n.t("system_variable.title"));
    }

}
</script>
